// you can extract this code in to page-app.js

jQuery(document).ready(function($){
	// define global variables so they don't have to be passed as arguments
	var wScroll =			$(window).scrollTop();
	var scrollOffset =		$('.nav').height();
	var scrollActual =		(wScroll + scrollOffset) - 1;
	var scrollSections =    [];
	
	var windowWidth   = $(window).width();
	var skyTop        = $('.contact').offset().top;
	var skyDistance;
	//dictionary of scrollTo sections
	var scrollToSectionDefs =
	{
		meetJoe: '#meet-joe',
		treeRemoval: '#service-tree-removal',
		stumpGrinding: '#service-stump-grinding',
		treePruning: '#service-tree-pruning',
		landClearing: '#service-land-clearing',
		serviceLocations: '#service-locations'
	}
	//begin setters(useful)
	var setWindowWidth = function(){
		windowWidth = $(window).width();
	}
	var setScrollOffset = function(){
		scrollOffset = $('.nav').height();
	}
	var setWScroll = function(){
		wScroll = $(window).scrollTop();
	}
	var setScrollActual = function(){
		scrollActual = wScroll + scrollOffset;
	};
	var setScrollSections = function(){
		scrollSections = [];
		$.each(scrollToSectionDefs, function(k, v){
			var target = v;
			var el = $(v);
			var tmpTop = el.offset().top;
			var tmpBottom = tmpTop + el.outerHeight();
			var scrollSection = 
			{
				title: k,
				target: target,
				el: el,
				topEdge: tmpTop,
				bottomEdge: tmpBottom
			}
			scrollSections[k] = scrollSection;
		});
	}
	//immediately invoke
	setScrollSections();

	var shiftClouds = function(){
		var docViewTop    = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();
		if (docViewBottom >= skyTop) {
			skyDistance = docViewBottom - skyTop;
		}
		//bind cloud movement to a percentage of the scroll position/window width at 75% ratio
		$(".cloud-left").css('left', (skyDistance * 75) / windowWidth +'%');
		$(".cloud-right").css('right', (skyDistance * 75) / windowWidth +'%');		
	}
	
	var fellTree = function(){
		$(".tree-primary").addClass("fell");
		// Hide tree after 4.5 seconds
		setTimeout(function() {
			$(".tree-primary").css("visibility", "hidden");
			$(".tree-primary").css("opacity", "0");
		}, 4500)	
	}
	
	// show/hide nav
	var toggleNavBar = function(){
		if(typeof scrollSections['meetJoe'] === 'object' && scrollSections['meetJoe'] !== null){ 
			if (scrollActual >= scrollSections['meetJoe'].topEdge) {
				$("body").addClass('nav-state-visible');
				return;
			}
			$("body").removeClass('nav-state-visible');
		}
	}

	var clearCurrentActiveNav = function() {
		$(".nav__sections-links > li").removeClass('current');
	}
	var checkTopEdge = function(s){
		var obj = scrollSections[s];
		return	scrollActual >= obj.topEdge;
	}
	var checkBottomEdge = function(s){
		var obj = scrollSections[s];
		return	scrollActual <= obj.bottomEdge;
	}	
	var isCurrentSection = function(section){
		return checkTopEdge(section) && checkBottomEdge(section);
	}
	
	var checkActiveSection = function(){
		$.each(scrollToSectionDefs, function(k,v){
			if(isCurrentSection(k)){
				clearCurrentActiveNav();
				$('a[href="' + scrollSections[k].target + '"]').closest('li').addClass('current');
				return;
			}
			$('a[href="' + scrollSections[k].target + '"]').closest('li').removeClass('current');
		});
	}

	var hasScrolled = function(){
		//update scroll values
		setWScroll();
		setScrollActual();
		//check navbar state
		toggleNavBar();
		//check if the top of the window is within a scrollTo section
		checkActiveSection();
		//move the clouds
		shiftClouds();
		if (skyDistance > 400) {
			//start the tree animation
			fellTree();
		}
	}
	var init = function(){
		setTimeout(function(){
			//create scroll section objects
			setScrollSections();
			//set the width var
			setWindowWidth();
			//see if the top of the screen is inside a scrollTo section
			checkActiveSection();
			shiftClouds();
			//check if nav should show
			toggleNavBar();
		},200);
	}
	// initialize our functionality
	init();
	
	// re-initialize on resize, to reset the top/bottom values of scrollTo sections
	$(window).on('resize', function(e){
		init();
	});
	//bind hasScrolled() to window scroll
	$(window).on('scroll', function(e){
		hasScrolled();
	});

	/*
	** @todo[4]: create hover intent functionality to delay the hover effect slightly 
	** for a less jittery experience when hovering quickly over the services panels
	**
	** begin "hover intent" for splash services
	*/
	var toggleActiveOff = function(){
	    $('.splash__services-service').removeClass('active');
	}
	var toggleActiveOn = function(target){
	    //@todo: just review
	    //toggle the active class on after 275ms - in the CSS we look for .slpash__services-service.active:hover
	    //by using that selector - we effectively create a hover intent
	    //for our elements on hover- and on mouseleave or :not(:hover) the effect is removed without delay
	    var $self = $(target);
	    setTimeout(function(){
	        $self.addClass('active');
	    },275);
	}
	//bind splash__services-service tile to the mouseenter/mouseleave events
	$('.splash__services-service')
	.on('mouseleave', function(leave){
	    toggleActiveOff();
	})
	.on('mouseenter', function(enter){
	    toggleActiveOn(enter.currentTarget);      
	});
	/*
	**  end "hover intent" for splash services
	*/	
});