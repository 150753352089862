$(document).ready(function() {

   // Elements with class of 'smooth-scroll-anchor'
   var dlSmoothScrollAnchor = $('.smooth-scroll-anchor');

   // Amount to offset the scroll
   var navBarHeight = $('.nav').height() - 1;

   var smoothScrollTo = function(anchorEl, offset){
   	$('html, body').animate({
   		scrollTop: $(anchorEl).offset().top - offset
   	}, 500);
   }

   // On click event of any element that has 'smooth-scroll-anchor' class
   dlSmoothScrollAnchor.on("click", function(e) {
      e.preventDefault();
      smoothScrollTo($(this).attr('href'), navBarHeight);
   });

});
