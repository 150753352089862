var inputs = document.querySelectorAll( '.free-estimate__project-photo' );
Array.prototype.forEach.call( inputs, function( input )
{
	var label	 = input.nextElementSibling,
		 labelVal = label.innerHTML;

	input.addEventListener( 'change', function( e )
	{
		var fileName = '';
		// @todo: may be personal preference, but I am not a fan of conditionals that dont wrap their functionality. 
		//** I think this looks more semantically correct:  if(condition){ do thing }else{ do other thing }
		// Example:		
		// if( this.files && this.files.length > 1 ){
		// 	fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
		// }else{
		// 	fileName = e.target.value.split( '\\' ).pop();
		// }
		
		if( this.files && this.files.length > 1 )
			fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
		else
			fileName = e.target.value.split( '\\' ).pop();

		if( fileName )
			label.querySelector( 'span' ).innerHTML = fileName;
		else
			label.innerHTML = labelVal;
	});
});
