$(document).ready(function() {

   /* SLICK CONFIG
   ============================================================================
   * CONFIGURATION FOR TESTIMONIALS
   * CONFIGURATION FOR RATINGS AND REVIEWS SITE LOGOS
   ========================================================================= */
   $('#testimonials-slick').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      dotsClass: 'slick-page-dots',
      arrows: false,
      mobileFirst: true,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               arrows: true
            }
         }
      ]
   });

   $('#ratings-logos-slick').slick({
      mobileFirst: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 992,
               settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
               }
            },
            {
               breakpoint: 1500,
               settings: {
                  slidesToShow: 4
               }
            }
         ]
   });


});
