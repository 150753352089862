// @todo: call jQuery(document) and pass $ to represent the jQuery obj - "safe-mode" in case any future js libraries conflict with this
jQuery(document).ready(function($){

   /* FILE UPLOAD
   ============================================================================
   * Handles states for file upload label
   ========================================================================= */
   // @todo[6]: this could be re-written with no JS - remember closing semi-colons as well
   //$('.free-estimate__project-photo').focus(function() {
	  //$('.free-estimate__project-photo-fake').css('background-color', '#232c27');

	  //$(this).focusout(function() {
		 //$('.free-estimate__project-photo-fake').css('background-color', '#888888');
	  //}) //;
   //}) //;

   // @todo: since we are already using jQuery here - we can select the element using jQuery - I like seeing some vanilla JS tho!
   // var skyHeight     = document.getElementById('contact-area').offsetHeight;
   //var skyHeight     = $('#contact-area').offsetHeight;
   //var windowWidth   = $( window ).width();
   //var scrollCheck   = 0;
   //var skyDistance;

	// @todo: this is where the idea of DRY code would apply - notice that .offset().top; and that same val + $(element).height(); 8 times - 
	//  we could refactor this into a function that accepts the element you want as an argument
	//  and spits back an object with the 2 values we need(could add the height value to the object for reference as well if needed)

   // Distance to top and bottom of sky area
   // var skyTop     = $('.contact').offset().top;
   // var skyBottom  = skyTop + $('.contact').height();

   //// Distance to top and bottom of Meet Joe area
   //var joeTop     = $('#meet-joe').offset().top;
   //var joeBottom  = joeTop + $('#meet-joe').height();

   //// Distance to top and bottom of each service section
   //var treeRemovalTop      = $('#service-tree-removal').offset().top;
   //var treeRemovalBottom   = treeRemovalTop + $('#service-tree-removal').height();

   //var stumpGrindingTop    = $('#service-stump-grinding').offset().top;
   //var stumpGrindingBottom = stumpGrindingTop + $('#service-stump-grinding').height();

   //var treePruningTop      = $('#service-tree-pruning').offset().top;
   //var treePruningBottom   = treePruningTop + $('#service-tree-pruning').height();

   //var loggingTop          = $('#service-logging').offset().top;
   //var loggingBottom       = loggingTop + $('#service-logging').height();

   //var landClearingTop     = $('#service-land-clearing').offset().top;
   //var landClearingBottom  = landClearingTop + $('#service-land-clearing').height();

   //// Distance to top and bottom of service locations section
   //var serviceLocationsTop    = $('#service-locations').offset().top;
   //var serviceLocationsBottom = serviceLocationsTop + $('#service-locations').height();


   /* CONTACT AREA
   ============================================================================
   * MOVING CLOUDS HORIZONTALLY ACROSS SKY
   * TRIGGERS TREE FALL
   ========================================================================= */

   // Removes all instances of .current from navigation elements
   //var clearCurrentActiveNav = function() {
	  //$(".nav__sections-links > li").each(function() {
		 //$(this).removeClass('current');
	  //});
   //}

   // Hides navigation when top edge of viewport is within splash area
   //@todo[2]: this todo required an update to the stylesheet as well- search scss folder for @todo[2]   
   // totally the right idea, I like how you are hiding and showing the nav with a transform(translate) instead of margins or top/bottom
   // positioning. We could write this into the css though - and save a calculation for windowWidth > 767 by using a media query within the
   // class.
   //var toggleNavBar = function(wScroll) {
	  //if ((wScroll + 75) >= joeTop && windowWidth > 767) {
		 //$(".nav").css("transform", "translate(0, 0)")
	  //}
	  //else {
		 //$(".nav").css("transform", "translate(0, -100%)")
	  //}
   //}
	
	
   // Highlights anchor on navbar that corresponds to area that user is currently scrolled to
   //var navCurrentSectionHighlight = function(wScroll) {
	  //if ((wScroll + 75) < joeTop) {
		 //clearCurrentActiveNav();
	  //}
	  //else if ((wScroll + 75) >= joeTop && (wScroll + 75) <= joeBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__meet-joe').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= treeRemovalTop && (wScroll + 75) <= treeRemovalBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__tree-removal').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= stumpGrindingTop && (wScroll + 75) <= stumpGrindingBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__stump-grinding').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= treePruningTop && (wScroll + 75) <= treePruningBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__tree-pruning').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= loggingTop && (wScroll + 75) <= loggingBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__logging').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= landClearingTop && (wScroll + 75) <= landClearingBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__land-clearing').addClass('current');
	  //}
	  //else if ((wScroll + 75) >= landClearingBottom && (wScroll + 75) < serviceLocationsTop) {
		 //clearCurrentActiveNav();
	  //}
	  //else if ((wScroll + 75) >= serviceLocationsTop && (wScroll + 75) <= serviceLocationsBottom) {
		 //clearCurrentActiveNav();
		 //$('.nav__service-locations').addClass('current');
	  //}
	  //else {
		 //clearCurrentActiveNav();
	  //}
   //}

   //var scrolledLocationsCheck = function(wScroll) {
	  //// Top and bottom edge of current scrolled position
	  //// @todo: these would be better in a global scope - then we can update the values within this function
	  //docViewTop    = $(window).scrollTop();
	  //docViewBottom = docViewTop + $(window).height();

	  //toggleNavBar(wScroll);

	  //navCurrentSectionHighlight(wScroll);

	  //// If bottom edge of screen has passed the top of the skyline
	  //if (docViewBottom >= skyTop) {
		 //skyDistance = docViewBottom - skyTop;
		 //console.log(skyDistance);
	  //}

	  //return true;
   //}

   /* PAGE SCROLLING EFFECTS
   ============================================================================
   * CONTROLS CLOUD HORIZONTAL SCROLLING
   * CONTROLS TREE ANIMATIONS
   ========================================================================= */

	// commenting out for refactor so I dont get errors 
   //$(window).scroll(function(event) {
	  //hasScrolled();
   //});

   //var hasScrolled = function() {
	  //wScroll = $(this).scrollTop();


	  //// Sky / Contact area effects
	  //if (scrolledLocationsCheck(wScroll)) {

		 //if (skyDistance > scrollCheck) {
			// scrollCheck = skyDistance;

			// // Move clouds in opposite directions across sky
			// $(".cloud-left").css('left', windowWidth / 100 + skyDistance);
			// $(".cloud-right").css('right', windowWidth / 100 + (skyDistance - 200));
		 //}

		 //// fell that fucking tree!
		 //if (skyDistance > 400) {
			// $(".tree-primary").addClass("fell");

			// // Hide tree after 4.5 seconds
			// setTimeout(function() {
			//   $(".tree-primary").css("visibility", "hidden");
			//   $(".tree-primary").css("opacity", "0");
			// }, 4500)
		 //}

	  //}

   //};

});
