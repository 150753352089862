;
(function ($, window, document) {
    var DLModal = function (elem, options) {
        this.elem = elem;
        this.$elem = $(elem);
        this.options = options;
        this.config = $.extend({}, this.defaults, this.options);
        if (this.config.autoInit === true) {
            if (this.init()) {
                window.hasDLModal = true;
            }
        }
    };

    DLModal.prototype = {
        defaults: {
            overlay: $('<div id="dlModalOverlay" />'),
            dlModalContent: false,
            dlModalMarkup: false,
            toggleTarget: false,
            defaultOpen: false,
            isIframe: false,
            iframeMarkup: false,
            autoPlay: false,
            iframeSrc: false,
            autoInit: true
        },

        init: function () {
            this.setToggleTarget();
            this.isIframe();
            this.isAutoPlay();
            this.setDefaultOpen();
            this.buildMarkup();

            this.attachEventListeners();
            return this;
        },
        setToggleTarget: function () {
            if (this.config.toggleTarget === false) {
                this.config.toggleTarget = this.$elem.attr('data-toggle-target');
            }
        },
        isIframe: function () {
            if (this.$elem.attr('data-type') === 'iframe' || this.config.isIframe) {
                this.config.isIframe = true;
                this.config.iframeSrc = this.$elem.attr('href');
            }
        },
        isAutoPlay: function () {
            if (this.config.autoPlay === false && this.$elem.attr('data-auto-play') === true) {
                this.config.autoPlay = true;
            }
        },
        /**
         ***     set the modal state body class
         **/
        setDefaultOpen: function () {
            if (this.isDefaultOpen() === true) {
                $('body').addClass('dlModalOpen');
            } else {
                $('body').addClass('dlModalClosed');
            }
        },
        buildMarkup: function () {
            var modalContent = this.getModalContent();
            var tmpTargetID = this.config.toggleTarget.replace('#', '');
            var defaultState = '';
            if (this.config.defaultOpen === true) {
                defaultState = "active";
            }
            var modalID = 'dlModal_' + tmpTargetID;
            if (!this.modalExistsForTargetID(modalID)) {
                $("<div>", {
                    class: defaultState + ' dlModal dlModal_' + tmpTargetID,
                    role: "dialogue",
                    id: modalID
                }).append(
                    $("<div>", {class: "dlModal-wrapper"}).append(
                        $("<div>", {class: "dlModal-body"}).append(
                            $("<div>", {class: "dlModal-content"}).html(modalContent)
                        ).append(
                            $("<button>", {class: "dlModal-close-btn"})
                        )
                    )
                ).appendTo("body");
            }
        },
        attachEventListeners: function () {
            var self = this;
            //close methods
            var closeBtn = $('.dlModal-close-btn');
            this.$elem.on('click', function (e) {
                e.preventDefault();
            });
            $(closeBtn).on('click', self, function(e){
                e.preventDefault();
                self.toggleToClosed();
            });
            this.config.overlay.on('click',function(){
                self.toggleToClosed();
            });
            if (window.hasDLModal !== true) {
                $(window).on('keyup', function (e) {
                    if (e.keyCode === 27) {
                        self.toggleToClosed();
                    }
                });
            }
            //open methods
            this.config.overlay.on('click', function (e) {
                e.preventDefault();
            });
            this.$elem.on('click', function(){
                self.toggleToOpen();
            });
        },
        isDefaultOpen: function () {
            return this.config.defaultOpen === true;
        },
        constructIframe: function (src) {
            var iframeElement = $('<iframe />');
            iframeElement.attr('src', src);
            return iframeElement;
        },
        toggleToOpen: function () {
            $('body').removeClass('dlModalClosed');
            $('body').addClass('dlModalOpen');
            var mod = this.config.toggleTarget.replace('#', '');
            var modClass = '.dlModal_' + mod;
            $(modClass).addClass('active');
        },
        toggleToClosed: function () {
            if ($('body').hasClass('dlModalOpen')) {
                $('body').removeClass('dlModalOpen');
                $('body').addClass('dlModalClosed');
            }
            $('.dlModal').removeClass('active');
            this.$elem.trigger('dlModal.close');
        },
        getModalContent: function () {
            var modalContent = false;
            if (this.config.isIframe === true) {
                modalContent = this.constructIframe(this.config.targetHref);
                //@todo: doesn't the above method return a jquery obj so we don't need to make a new one on the next line?
                modalContent = $(modalContent[0]).prop('outerHTML');
            } else if (this.config.dlModalContent !== false) {
                modalContent = this.config.dlModalContent;
            } else {
                modalContent = $(this.$elem.attr('href')).html();
            }
            return modalContent;
        },
        modalExistsForTargetID: function (modalID) {
            return typeof $('#' + modalID)[0] !== 'undefined';
        }
    };

    DLModal.defaults = DLModal.prototype.defaults;
    window.hasDLModal = false;
    $.fn.dlModal = function (options) {
        DLModal.defaults.overlay.appendTo($('body'));
        return this.each(function () {
            new DLModal(this, options);
        });
    };
    //optional: window.DLModal = DLModal;
})(jQuery, window, document);

jQuery(document).ready(function(){
    if($('#server-side-message').length){
    	var successMessageOptions = {
    		dlModalContent: $('#server-side-message'),
    		defaultOpen: true
    	};
    	$('.ss-message-trigger').dlModal(successMessageOptions);
    }
    
    var tos = {
    		dlModalContent: $('#terms-and-privacy'),
    		defaultOpen: false
    	};
	$('.footer__privacy-link').dlModal(tos);
    
});